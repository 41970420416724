/* LOUNGE.SCSS
/* for ABOUT DEALERS LOUNGE
------------------------------ */
.blog {
	.page-hero {
		.container {
			position: relative;
		}
	}
	.hero-title {
		position: absolute;
		top: 32%;
		left: 30px;
		@media (max-width: 991px) {
			position: static;
			margin-left: 30px;
			padding-top: 3.5rem;
			padding-bottom: 2rem;
		}
		@media (max-width: 575px) {
			position: static;
			margin-left: 30px;
			padding-top: 2.5rem;
			padding-bottom: 1.5rem;
		}
		color: #fff;
		.class-title {
			font-size: 2.625rem;
			line-height: 1.0;
			font-family: Arial;
			font-weight: bold;
			margin-bottom: 0;
		}
		.tagline-h2 {
			font-size: 1rem;
			font-weight: bold;
		}
	}
}

.blog {
&.blog-new {
	.page-hero {
		background: url(/images/blog/hero_new.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_new.png) 0 0 repeat-x;
		@media (max-width: 1199px) {
			background-image: none;
		}
		@media (max-width: 991px) {
			background: url(/images/blog/hero_new.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_new.png) 0 0 repeat-x;
		}
	}
}
&.blog-custom {
	.page-hero {
		background: url(/images/blog/hero_custom.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_custom.png) 0 0 repeat-x;
		@media (max-width: 1199px) {
			background-image: none;
		}
		@media (max-width: 991px) {
			background: url(/images/blog/hero_custom.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_custom.png) 0 0 repeat-x;
		}
	}
}
&.blog-event {
	.page-hero {
		background: url(/images/blog/hero_event.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_event.png) 0 0 repeat-x;
		@media (max-width: 1199px) {
			background-image: none;
		}
		@media (max-width: 991px) {
			background: url(/images/blog/hero_event.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_event.png) 0 0 repeat-x;
		}
	}
}
&.blog-pick {
	.page-hero {
		background: url(/images/blog/hero_pick.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_pick.png) 0 0 repeat-x;
		@media (max-width: 1199px) {
			background-image: none;
		}
		@media (max-width: 991px) {
			background: url(/images/blog/hero_pick.jpg) 50% 0 no-repeat, url(/images/blog/bg_hero_pick.png) 0 0 repeat-x;
		}
	}
}
}


.contents-wrapper {
	.container {
		max-width: 1200px;
		padding-left: 0;
		padding-right: 0;
		@media (max-width: 991px) {
			padding-left: 1rem;
			padding-right: 1rem;
		}
	}
	.content {
		padding-left: 20px;
		@media (max-width: 991px) {
			padding-left: 0;
		}
		.section-content {
			color: #666;
			padding-bottom: 6.125rem;
			@media (max-width: 767px) {
				padding-bottom: 2.75rem;
			}
		}
	}
	.sidebar {
		padding-right: 20px;
		padding-bottom: 5rem;
		@media (max-width: 991px) {
			padding-right: 0;
		}
		h2 {
			font-size: 1.5625rem;
			font-weight: bold;
			color: #666;
			background-color: #ececec;
			border-top: 2px solid #ddd;
			border-left: 2px solid #ddd;
			border-right: 2px solid #ddd;
			display: block;
			padding: 10px 14px;
			margin-bottom: 0;
			span {
				font-size: 0.9375rem;
				display: block;
			}
		}
		ul.sidenavi {
			padding-left: 0;
			list-style: none;
			border-top: 3px solid #ddd;
			margin-bottom: 1.625rem;
			li {
				font-size: .875rem;
				line-height: 1.4;
				width: 100%;
				border-bottom: 3px solid #ddd;
				border-left: 3px solid #ddd;
				border-right: 3px solid #ddd;
				a {
					color: #666;
					width: 100%;
					display: block;
					text-decoration: underline;
					padding-top: 1.25rem;
					padding-bottom: 1.25rem;
					padding-left: .75rem;
					padding-right: 1.25rem;
					background: url(../images/common/bullet_right_angle_g.png) right 5px top 50% no-repeat;
				&.list-collapsed {
					background: url(../images/common/bullet_under_arrow_g.png) right 5px top 50% no-repeat;
				}
				}
				ul {
					padding-left: 0;
					list-style: none;
					border-top: none;
					margin-bottom: 1rem;
					margin-top: -0.5rem;
					li {
						font-style: .875rem;
						line-height: 1.4;
						background-image: none;
						border: none;
						a {
							padding-top: .5rem;
							padding-bottom: .5rem;
							background-image: none;
						&.list-collapsed {
							background-image: none;
						}
						}
					}
			}

			}

		&.has-children {
			li {
				background-position: right 5px top 1.45rem;
			}
		}
		}

		.sns-links {
			h3 {
				margin-bottom: 0;
				margin-left: 2rem;
				margin-bottom: 1rem;
				line-height: 1.2;
				font-size: .9375rem;
				span.cname {
					font-size: 1.0625rem;
					display: block;
					color: #666;
				}
			}
			.icons {
				margin-left: 2rem;
				a {
				&:hover {
					text-decoration: none;
					img {
						opacity: 0.7;
					}
				}
				}
			}
		}
		.form-wrapper {
			width: 100%;
			border-bottom: 3px solid #ddd;
			border-left: 3px solid #ddd;
			border-right: 3px solid #ddd;
			padding: 2rem 0.75rem;
			margin-bottom: 1rem;
			form {
				select {

				}
			}
		}
	}
}

.breadcrumbs {
	padding-bottom: 1.25rem;
}

.category-title {
	color: #fff;
	margin-bottom: 1.75rem;
	@media (max-width: 767px) {
		margin-bottom: 1rem;
	}
	h3 {
		border-top: 5px solid #ddd;
		font-size: 1.125rem;
		line-height: 1.0;
		span {
			background-color: #000;
			display: inline-block;
			font-weight: bold;
			margin-top: -5px;
		}
	}
}

.blog {
	.category-title {
		h3 {
			font-size: 0;
			span {
			&.parent-cat {
				font-size: 1rem;
				padding: 10px 18px 10px 18px;
			}
			&.current-cat {
				background-color: #333;
				font-size: 1rem;
				font-weight: normal;
				padding: 10px 18px 10px 18px;
			}
			@media (max-width: 767px) {
			&.parent-cat {
				font-size: .9375rem;
				display: block;
			}
			&.current-cat {
				font-size: .9375rem;
				display: block;
				margin-top: 0;
			}
			}
			}
		}
	}
&.blog-new {
	.category-title {
		h3 {
			span {
			&.parent-cat {
				border-left: 10px solid #c00;
			}
			}
		}
	}
}
&.blog-custom {
	.category-title {
		h3 {
			span {
			&.parent-cat {
				border-left: 10px solid #039;
			}
			}
		}
	}
}
&.blog-event {
	.category-title {
		h3 {
			span {
			&.parent-cat {
				border-left: 10px solid #390;
			}
			}
		}
	}
}
&.blog-pick {
	.category-title {
		h3 {
			span {
			&.parent-cat {
				border-left: 10px solid #f60;
			}
			}
		}
	}
}
}

.blog {
	.post-date {
		font-weight: bold;
		font-size: 1.25rem !important;
		margin-bottom: .25rem;
		padding-top: 0 !important;
		line-height: 1.2;
	}
	.post-title {
		color: #000;
		font-weight: bold;
		font-size: 1.25rem !important;
		margin-bottom: .25rem;
		padding-left: 0 !important;
		line-height: 1.2;
		a {
			color: #000;
		}
	}
	@media (max-width: 767px) {
		.post-date,
		.post-title {
			font-size: 1rem !important;
		}
		.post-excerpt {
			p {
				font-size: .9375rem;
			}
		}
		.readmore {
			display: block;
			padding-top: .25rem;
			// text-align: right;
		}
		.post-cat {
			// text-align: right;
		}
	}
&.blog-new {
	.post-date {
		color: #c00;
	}
}
&.blog-custom {
	.post-date {
		color: #039;
	}
}
&.blog-event {
	.post-date {
		color: #390;
	}
}
&.blog-pick {
	.post-date {
		color: #f60;
	}
}
}

.blog-single {
	.title-wrapper {
		padding-bottom: 1.875rem;
		margin-bottom: 1.875rem;
		border-bottom: 1px solid #ddd;
	}
}

.post-list {
	.post-item {
		padding-bottom: 3rem;
		border-bottom: 1px solid #ddd;
		margin-bottom: 1.5rem;
		@media (max-width: 767px) {
			padding-bottom: 3rem;
			margin-bottom: 0;
		}
		.post-thumb {
			text-align: left;
			padding: 0 auto 1rem;
			img {
				max-width: 100%;
				min-height: 100%;
				object-fit: cover;
				object-position: 50% 50%;
			}
		@media (max-width: 767px) {
			text-align: center;
			img {
				// object-position: top center;
				padding-left: 0;
				padding-right: 0;
				padding-top: 0;
			}
		}
		}
		.post-content {
			padding-left: 1rem;
			@media (max-width: 767px) {
				padding-left: 0;
			}
		}
		.readmore {
			margin-left: 1rem;
			color: #c00;
			text-decoration: underline;
		}
	}
	.post-cat {
		a {
			font-weight: bold;
			text-decoration: underline;
			padding: 5px 1rem;
		}
	}
}

.blog {
&.blog-new {
	.post-cat {
		a {
			border: 2px solid #c00;
			color: #c00;
		}
	}
}
&.blog-custom {
	.post-cat {
		a {
			border: 2px solid #039;
			color: #039;
		}
	}
}
&.blog-event {
	.post-cat {
		a {
			border: 2px solid #390;
			color: #390;
		}
	}
}
&.blog-pick {
	.post-cat {
		a {
			border: 2px solid #f60;
			color: #f60;
		}
	}
}
}

.post {
	.post-date {
		padding-top: 2px;
	}
	.post-title {
		font-size: 1.625rem;
		line-height: 1.2;
	}
}


.post-body {
	min-height: 500px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	@media (max-width: 767px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.pagelinks {
	padding-bottom: 2rem;
}

.page-nav {
	padding-bottom: 2rem;
	ul {
		padding-left: 0;
		list-style: none;
		li {
			// display: block;
			margin-bottom: 1.375rem;

			a {
				display: block;
				width: 100%;
				padding-top: 13px;
				padding-bottom: 12px;
			}
		&.prev,
		&.next {
			background-color: #ededed;
			border: 1px solid #000;
			a {
				font-size: 1.0625rem;
				font-weight: bold;
				color: #000;
				text-decoration: underline;
			}
		}
		&.prev {
			text-align: right;
			border-right: 50px solid #000;
			a {
				padding-right: 1.5rem;
			}
		}
		&.next {
			border-left: 50px solid #000;
			a {
				padding-left: 1.5rem;
			}
		}
		&.blog-top {
			background-color: #000;
			text-align: center;
			a {
				font-size: 1.5rem;
				font-weight: bold;
				color: #fff;
				font-weight: bold;
				padding-top: 7px;
				padding-bottom: 7px;
				span {
					margin-left: -2rem;
					display: inline-block;
					padding-left: 2rem;
					background: url(../images/common/bullet_up_arrow_w.png) 0 50% no-repeat;
				}
			}
		}
		}
	}
}



/* WordPress Galleries Settings
/* Over Written
------------------------------ */
.gallery-item {
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin: 0 0 1rem;
	padding: 0 .25rem 0 .25rem;
	width: 50%;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus,
.widget-area .gallery-item a,
.widget-area .gallery-item a:hover,
.widget-area .gallery-item a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	display: inline-block;
	max-width: 100%;
}

.gallery-item a img {
	display: block;
	-webkit-transition: -webkit-filter 0.2s ease-in;
	transition: -webkit-filter 0.2s ease-in;
	transition: filter 0.2s ease-in;
	transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	width: 100%;
	height: 100%;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
	-webkit-filter: opacity(60%);
	filter: opacity(60%);
}

.gallery-caption {
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
	margin-bottom: 0;
}

/* Gallery widget */

.gallery-columns-5 .gallery-caption,
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

	.gallery-item {
		max-width: 25%;
	}

	.gallery-columns-1 .gallery-item {
		max-width: 100%;
	}

	.gallery-columns-2 .gallery-item {
		max-width: 50%;
	}

	.gallery-columns-3 .gallery-item {
		max-width: 33.33%;
	}

	.gallery-columns-4 .gallery-item {
		max-width: 25%;
	}

	.gallery-columns-5 .gallery-item {
		max-width: 20%;
	}

	.gallery-columns-6 .gallery-item {
		max-width: 16.66%;
	}

	.gallery-columns-7 .gallery-item {
		max-width: 14.28%;
	}

	.gallery-columns-8 .gallery-item {
		max-width: 12.5%;
	}

	.gallery-columns-9 .gallery-item {
		max-width: 11.11%;
	}